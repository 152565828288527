@font-face {
    font-family: 'ShroomsFont';
    src: local('ShroomsFont'), url(../fonts/ChalkboardSE-Bold.woff) format('woff2');
  
  }

  @font-face {
    font-family: 'ShroomsFontLight';
    src: local('ShroomsFontLight'), url(../fonts/ChalkboardSE-Light.woff) format('woff2');
  
  }

.backgroundImg{
    position:fixed;
    top: 100px;
    width:100%;
    
}

.mainCointainer{
    margin-top: 100px;
    /*background-color: #271e36; /*#271e36; */
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.atmImg{
    
    height: 90%;
    
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    /*border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #ffffff;*/
    
    
    
}

.textContainer{
    margin-top: 180px;
    position: absolute;
    /*width: 55%;*/
    max-width: 800px;
    min-width: 330px;
    /*left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);*/
    border-style: solid;
    border-width:0px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #ffffff;
    /*padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;*/
    padding: 10px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
}

.msgContainer{
  display:none;
  margin-top: 150px;
  position: absolute;
  /*width: 55%;*/
  max-width: 900px;
  min-width: 330px;
  /*left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);*/
  border-style: solid;
  border-width:1px;
  border-radius: 10px;
  border-color: rgb(194, 194, 194);
  background-color: #271e36;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
}

.option3Container{
  display: none;
    position:relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 330px;
    min-width: 300px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #ffffff;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
}


.normalText{
    position: relative;
    color: rgb(65, 64, 64);
    font-size: 18px;
    font-family: "ShroomsFontLight";
    
}

.normalLink{
    color:rgb(214, 47, 53);
}

.atmScreen{
    
    position: relative;
    padding: 2%;
    padding-top: 36%;
    text-align: center;
    
    border-radius: 5px;
    
}

.atmContainer{
    
    height: 100vh;
    width: 100vh;
    position: relative;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-bottom: 2%;
    
}

.atmText{
    font-size: 3vh;
    color: white;
    font-family: "ShroomsFontLight";
}

.uglyRange{
    background-color: #141616;
    color: white;
    border-radius: 10px;
    text-align: center;
}

.swapButton{
    background-color: rgb(227, 198, 82);
    border-color: rgb(157, 137, 26);
    border-width: 1px;
    color: white;
    border-radius: 10px;
    border-style: solid;
    margin-top: 2%;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "ShroomsFont";
    padding-top: 5px;
    padding-bottom: 5px;
}
.swapButton:hover{
    background-color: rgb(136, 216, 114);
    border-color: rgb(67, 139, 12);
}

.qMark{
    color: white;
    font-size:2vh;
    vertical-align: super;
}

.qMark .tooltiptext {
    visibility: hidden;
    background-color: rgb(227, 198, 82);
    color: #141616;
    border-radius: 10px;
    border-style: solid;
    border-color: #323535;
    border-width: 1px;
    font-size:2vh;
    /* Position the tooltip */
    position: absolute;
    left: 60%;
    
  }

.qMark:hover .tooltiptext {
    visibility: visible;
    
  }

.spacerElement{
    height: 2hv;
}

.containerOfATMContainer{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    min-width: 340px;   
    width: 900px;
    min-height: 255px;
}


.footerContainer{
  position:relative;
  bottom:0vh;
  width:100%;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width:1px;
  background-color: #ffffff;
  padding: 1px;
  text-align: center;

}

@media screen and (max-width: 1300px) {
.backgroundImg{
    /*display: none;*/
    width: unset;
}
}

@media screen and (max-width: 800px) {
    .backgroundImg{
        display: none;
    }
    .atmContainer{
      display: none;
    }
    .option3Container{
      display: block;
      margin-bottom: 25px;
      border-color: rgb(194, 194, 194);
  }
    .textContainer{
        border-color: rgb(194, 194, 194);
    }
    .uglyRange{
      background-color: #ffffff;
      color: rgb(51, 51, 51);
    }
}

@media screen and (max-width: 800px) {
    .textContainer{
      margin-top: 180px;
    }
    .containerOfATMContainer{
        width: 100%;
    }
}

