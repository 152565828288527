@font-face {
    font-family: 'ShroomsFont';
    src: local('ShroomsFont'), url(../fonts/ChalkboardSE-Bold.woff) format('woff2');
  
  }

  @font-face {
    font-family: 'ShroomsFontLight';
    src: local('ShroomsFontLight'), url(../fonts/ChalkboardSE-Light.woff) format('woff2');
  
  }

.header-container {
    
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100px;
    background-color: rgba(255, 255, 255, 1);/*#271e36; */
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    justify-content: center;
    
}

.connectBtn {
    position: absolute;
    
    right: 40px;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;
    font-size: 18px;
    border:1px;
    border-style: solid;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: rgb(227, 198, 82);
    font-weight:700;
    font-family: "ShroomsFontLight";
}

.connectBtn:hover{
    background-color: rgb(136, 216, 114);
}

.siteTitle {
    position: absolute;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgb(50, 50, 50);
    font-size: 35px;
    font-weight: bold ;
    font-family: "ShroomsFont";
}

.siteTitleImage {
    position: absolute;
    
    left: 50%;

    
    border:1px;
    border-style:none;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
    
}


.siteLogo {
    position: absolute;
    
    left: 40px;

    
    border:1px;
    border-style:none;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
}

@media screen and (max-width: 800px) {
    .connectBtn{
        right: 20px;
    }
    .siteLogo{
        left:10px;   
        width: 70px;
        height: 70px;
    }
    .siteTitle{
        display: none;
    }
}