
.outSideBufferWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainBufferWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainBufferWindow-Body {
    
    position: absolute;
    padding: 32px;
  text-align: center;
    max-width: 480px;
    border-radius: 15px;
    /*background-color: rgb(39, 30, 54);
    border-color: rgb(63, 48, 85);*/
    background-color: rgb(227, 198, 82);
    border-color: rgb(157, 137, 26);
    border-width: 1px;
    border-style: solid;
    
}

.mainBufferWindow-Body .connectWalletButton {
    position: relative;
    
    padding: 10px;
    margin: 5px;
    width: 100%;
    
    border-radius: 10px;
    background-color: #beb5ff;
    font-size: 20px;
    border-style: solid;
    border-color: #8177c9;
    border-width: 1px;
    
}

.connectWalletButton:hover{
    background-color: #d3cff8;
}

.mainBufferWindow-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    color: rgb(235, 235, 235);
}


.mainBufferWindow-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;

    color: rgb(235, 235, 235);
}

.horizontal-center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .bufferMsgFont{
    position: relative;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-bottom: 5%;
}

/*.msgImage{
  margin-left:auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  
}*/
.bufferIcon{
  position: relative;
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.bufferMsgImage{
    
  
  width: 100%;
  position: relative;
  
  margin-left:auto;
  margin-right: auto;
  
  
  
}

.bufferOkButton {
  position: relative;
  margin-top: 5%;
  padding: 5px;
  margin-right: 5px;
  width: 50%;
  max-width: 280px;
  border-radius: 10px;
  background-color: #beb5ff;
  border-color: rgb(63, 48, 85);
  border-style: solid;
  border-width: 1px;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 18px;
  font-weight:600;
  color: black;
}

.bufferOkButton:hover{
  background-color: #d3cff8;
}