@font-face {
    font-family: 'ShroomsFont';
    font-style: bold;
    src: local('ShroomsFont'), url(../fonts/ChalkboardSE-Bold.woff) format('woff2');
  
  }

  @font-face {
    font-family: 'ShroomsFontLight';
    src: local('ShroomsFontLight'), url(../fonts/ChalkboardSE-Light.woff) format('woff2');
  
  }

.outSideDropDown {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}


.walletDropDown-Body {
    
    position: absolute;
    top: 74px;
    right: 40px;
    padding: 16px;
    max-width: 360px;
    width:310px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: rgb(227, 198, 82);
    background-color: rgb(227, 198, 82);
    font-family: "ShroomsFontLight";
}

.changeWalletButton {
    position: relative;
    
    padding: 5px;
    margin-right: 5px;
    width: 100%;
    max-width: 280px;
    border-radius: 10px;
    background-color: rgb(227, 198, 82);
    border-color: rgb(157, 137, 26);
    border-style: solid;
    border-width: 1px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 18px;
    font-weight:600;
    color: rgb(50, 50, 50);
}

.changeWalletButton:hover{
    background-color: rgb(136, 216, 114);
    border-color: rgb(67, 139, 12);
}

.walletIcon {
    position:relative;
    padding: 5px;
    margin-right: 20px;
    
    float: left;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    border-style: none;
    background-color: #ffffff;
}

.walletAssetsArea{
    position: relative;
    max-height: 800px;
    padding: 2px;
    margin: 2px;
}

.walletAssetQuantity{
    position: relative;
    padding: 5px;
    margin: 5px;
    float: right;
}

.walletNameFont{
    font-size: 18px;
    font-weight: bold;
    color: rgb(50, 50, 50);
}

.addressFont{
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 50, 50);
}

.balanceFont{
    font-size: 16px;
    font-weight: bold;
    color: rgb(50, 50, 50);
}

.horizontalLine{
    color: #323535;
    border-color:rgb(157, 137, 26);
    padding: 0px;
    margin-top:0px;
  
}