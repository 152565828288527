@font-face {
    font-family: 'ShroomsFont';
    src: local('ShroomsFont'), url(../fonts/ChalkboardSE-Bold.woff) format('woff2');
  
  }

  @font-face {
    font-family: 'ShroomsFontLight';
    src: local('ShroomsFontLight'), url(../fonts/ChalkboardSE-Light.woff) format('woff2');
  
  }

.outSideSuccessWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainSuccessWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainSuccessWindow-Body {
    
    position: absolute;
    padding: 32px;

    max-width: 480px;
    border-radius: 15px;
    background-color: rgb(227, 198, 82);
    border-color: rgb(157, 137, 26);
    border-width: 1px;
    border-style: solid;
    
}

.mainSuccessWindow-Body .connectWalletButton {
    position: relative;
    
    padding: 10px;
    margin: 5px;
    width: 100%;
    
    border-radius: 10px;
    background-color:rgb(67, 139, 12);
    font-size: 20px;
    border-style: solid;
    border-color: #8177c9;
    border-width: 1px;
    
}

.connectWalletButton:hover{
    background-color: #d3cff8;
}

.mainSuccessWindow-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    font-family: "ShroomsFont";
    color: rgb(50, 50, 50);
}


.mainSuccessWindow-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    font-family: "ShroomsFontLight";
    color: rgb(50, 50, 50);
}

.horizontal-center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .successMsgFont{
    position: relative;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: rgb(50, 50, 50);
    text-align: center;
    margin-bottom: 5%;
    font-family: "ShroomsFontLight";
}

/*.msgImage{
  margin-left:auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  
}*/

.successMsgImage{
    
  
  width: 100%;
  position: relative;
  
  margin-left:auto;
  margin-right: auto;
  
  
  
}

.successOkButton {
  position: relative;
  margin-top: 5%;
  padding: 5px;
  margin-right: 5px;
  width: 50%;
  max-width: 280px;
  border-radius: 10px;
  background-color: rgb(136, 216, 114);
  border-color: rgb(67, 139, 12);
  border-style: solid;
  border-width: 1px;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 18px;
  font-weight:600;
  font-family: "ShroomsFontLight";
  color: black;
}

.successOkButton:hover{
  background-color: rgb(67, 139, 12);
}