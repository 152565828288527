@font-face {
    font-family: 'ShroomsFont';
    font-style: bold;
    src: local('ShroomsFont'), url(../fonts/ChalkboardSE-Bold.woff) format('woff2');
  
  }

  @font-face {
    font-family: 'ShroomsFontLight';
    src: local('ShroomsFontLight'), url(../fonts/ChalkboardSE-Light.woff) format('woff2');
  
  }

.outSidePopupWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}


.popupWindow-Body {
    
    position: absolute;
    top: 74px;
    right: 40px;
    padding: 16px;
    max-width: 360px;
    width:310px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: rgb(157, 137, 26);
    background-color: rgb(227, 198, 82);
}

.okButton {
    position: relative;
    
    padding: 5px;
    margin-right: 5px;
    width: 50%;
    max-width: 280px;
    border-radius: 10px;
    background-color: rgb(227, 198, 82);
    border-color: rgb(157, 137, 26);
    border-style: solid;
    border-width: 1px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 18px;
    font-weight:600;
    font-family: "ShroomsFontLight";
    color: rgb(50, 50, 50);
}

.okButton:hover{
    background-color: rgb(136, 216, 114);
    border-color: rgb(67, 139, 12);
}


.msgFont{
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 50, 50);
    text-align: center;
    margin-bottom: 5%;
    font-family: "ShroomsFontLight";
}

@media screen and (max-width: 800px) {
    .popupWindow-Body{
        right: auto;
    }
}