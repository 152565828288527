
@font-face {
    font-family: 'ShroomsFont';
    font-style: bold;
    src: local('ShroomsFont'), url(../fonts/ChalkboardSE-Bold.woff) format('woff2');
  
  }

  @font-face {
    font-family: 'ShroomsFontLight';
    src: local('ShroomsFontLight'), url(../fonts/ChalkboardSE-Light.woff) format('woff2');
  
  }

.walletsMenu {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.walletsMenu-Body {
    
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 360px;
    border-radius: 10px;
    background-color: rgb(227, 198, 82);/*#271e36;*/
    font-family: "ShroomsFontLight";
    border-color: rgb(157, 137, 26);/*rgb(63, 48, 85);*/
    border-style: solid;
    border-width: 1px;
}

.walletsMenu-Body .connectWalletButton {
    position: relative;
    
    padding: 10px;
    margin: 5px;
    width: 100%;
    max-width: 280px;
    border-radius: 10px;
    background-color: rgb(227, 198, 82);
    font-size: 20px;
    border-style: solid;
    border-color:rgb(157, 137, 26);
    border-width: 1px;
    color: rgb(50, 50, 50);
    
}

.walletsMenu-Body .connectWalletButton:hover{
    background-color: rgb(136, 216, 114);
    border-color: rgb(67, 139, 12);
}

.walletsMenu-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    font-weight: bold;
    color: rgb(50, 50, 50);
}


.walletsMenu-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    color: rgb(50, 50, 50);
    font-weight: bold;
}

.horizontal-center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

